import metricsClient from "@/axiosClients/metricsClient";
import discountCodesClient from "@/axiosClients/discountCodesClient";

const downloadSubsequestSessionsReport = (data) => {
    return metricsClient.post(`/subsequent-sessions/generate-report`, data, {
        responseType: 'blob'
    });
}

const downloadInitialSubsequentEvaluationReport = (data) => {
    return metricsClient.post(`/subsequent-initial-answers/generate-report`, data, {
        responseType: 'blob'
    });
}

const downloadWaitingListReport = (data) => {
    return metricsClient.post(`/waiting-list/generate-report`, data, {
        responseType: 'blob'
    });
}

const downloadWeeklyUsersReport = (data) => {
    return metricsClient.post(`/weekly`, data, {
        responseType: 'blob'
    });
}

const setVideoViewerMetric = (data) => {
    return metricsClient.post(`/upcoming-talks/user/saw-video`, data);
}


export default {
    downloadSubsequestSessionsReport,
    setVideoViewerMetric,
    downloadInitialSubsequentEvaluationReport,
    downloadWaitingListReport,
    downloadWeeklyUsersReport
}
