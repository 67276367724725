import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import useDownloads from '../composables/useDownloads';
const {
    downloadExcelReportPatientInformation,
    downloadExcelReportInitialAndSubsequentsEvaluations,
    downloadExcelReportWaitingList,
    openWeeklyClientsReportModal
} = useDownloads();
export default {
    name: "admin-reports",
    component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
    children: [
        {
            path: "evaluations",
            name: "admin-evaluations-reports",
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.admin-evaluation-reports',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "admin-evaluations-reports" */ "@/modules/admin/reports/evaluations/views/Evaluations.vue"),
        },
        {
            path: "user-information",
            name: "user-information-reports",
            beforeEnter: [isVerifyTokenGuard, function (to, from, next) {
                downloadExcelReportPatientInformation();

                next(false); // Prevent navigation

            }],
        },
        {
            path: "evaluations-information",
            name: "evaluations-information-reports",
            beforeEnter: [isVerifyTokenGuard, function (to, from, next) {                
                downloadExcelReportInitialAndSubsequentsEvaluations();

                next(false); // Prevent navigation

            }],
        },
        {
            path: "waiting-list",
            name: "waiting-list-reports",
            beforeEnter: [isVerifyTokenGuard, function (to, from, next) {                
                downloadExcelReportWaitingList();

                next(false); // Prevent navigation

            }],
        },
        {
            path: "weekly-users",
            name: "weekly-users-reports",
            beforeEnter: [isVerifyTokenGuard, function (to, from, next) {                
                openWeeklyClientsReportModal();
                next(false); // Prevent navigation
            }],
        }
    ]
};
