import {downloadFile} from "@/utils/filesUtil";
import moment from "moment";
import patientService from "@/services/patientService";
import metricsService from "@/services/metricsService";
import store from "@/store";

const useDownloads = () => {


    const downloadExcelReportPatientInformation = async () => {

        store.commit('setGeneralIsLoading', true)
    
        const fileName = "informacion-usuarios-"
    
        const response = await patientService.getExcelUserInformation();
        downloadFile(response.data, `${fileName}${moment().format("DD_MM_YYYY")}`)
    
        store.commit('setGeneralIsLoading', false)
    
    }

    const downloadExcelReportInitialAndSubsequentsEvaluations = async () => {

        store.commit('setGeneralIsLoading', true)
    
        const fileName = "evaluaciones-iniciales-subsecuentes-"
    
        const response = await metricsService.downloadInitialSubsequentEvaluationReport(null);
        downloadFile(response.data, `${fileName}${moment().format("DD_MM_YYYY")}`)
    
        store.commit('setGeneralIsLoading', false)
    
    }

    const downloadExcelReportWaitingList = async () => {

        console.log("composable");

        store.commit('setGeneralIsLoading', true)
    
        const fileName = "lista-de-espera-"
    
        const response = await metricsService.downloadWaitingListReport(null);
        downloadFile(response.data, `${fileName}${moment().format("DD_MM_YYYY")}`)
    
        store.commit('setGeneralIsLoading', false)
    
    }

    const openWeeklyClientsReportModal = () => {
        const downloadModal = document.querySelector('#weeklyReportDownload')
        const modal = bootstrap.Modal.getOrCreateInstance(downloadModal)
        modal.show()
    }

    return {
        downloadExcelReportPatientInformation,
        downloadExcelReportInitialAndSubsequentsEvaluations,
        downloadExcelReportWaitingList,
        openWeeklyClientsReportModal
    }

}

export default useDownloads;
